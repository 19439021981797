@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// # Mobile
// only screen and (min-width: 480px)
// # Tablet
// only screen and (min-width: 768px) 
// # Desktop
// only screen and (min-width: 992px)
// # Huge
// only screen and (min-width: 1280px) 

:root {
  --color-primary: #98151D;
  --color-primary-v2: #BF1721;
  --color-secondary: #ffffff;
  --color-secondary-v2: #f1f5f9;
  --color-black: #000000;
  --color-black-v2: #212b36;
  --color-rgb-black:  0, 0, 0; /* Black */
  --color-rgb-white:  255, 255, 255; /* White */
  --color-gradient-primary: linear-gradient(to bottom right, var(--color-primary-v2), var(--color-primary));
  --color-gradient-secondary: linear-gradient(to bottom right, #DB242F, var(--color-primary));
  --color-blue: #043A58;
  --color-brown: #414141;
  --color-shadow: rgba(49, 53, 59, 0.12) 0px 1px 6px 0px;
  --color-shadow-v2: rgba(49, 53, 59, 0.12) 0px 1px 15px 0px;
  --color-shadow-v3: rgb(0, 0, 0) 0px 5px 15px 0px;
  --color-shadow-v4: rgba(0, 0, 0, 0.2) 20px 25px 15px 0px;
  --color-text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  --color-gray: #5B5B5B;
  --color-gray-v2: #8D8D8D;
  --color-gray-v3: #A4A4A4;
  --color-gray-v4: #515151;
  --color-gray-v5: #2D2D2D;
  --color-gray-v6: #919eab;
  --color-gray-v7: #D8D8D8;
  --color-gray-v8: #F0F0F0;
  --color-gray-v9: #8C8C8C;
  --color-gray-v10: #7F7F7F;
  --color-gray-v11: #BBBBBB;
  --color-gray-v12: #ECECEC;
  --color-red: #DB242F;
  --color-yellow: #FFCD92;

  --color-card-dashboard-success: linear-gradient(to right, #1cc88a 10px, transparent 10px);
  --color-card-dashboard-warning: linear-gradient(to right, #ffcc00 10px, transparent 10px);
  --color-card-dashboard-danger: linear-gradient(to right, #FF0000 10px, transparent 10px);
  --color-card-dashboard-primary: linear-gradient(to right, #BF1721 10px, transparent 10px);
}
* {
  font-family: 'Poppins'
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.development-notice{
  z-index: 9999;
  background-color:var(--color-primary-v2);
  color: var(--color-secondary);
  text-align: center;
  position:fixed;
  bottom: 0;
  width: 100%;
}

@media (min-width: 1500px) {
  .container-fluid {
    max-width: 2300px !important;
  }
  main > .container-fluid {
    padding-bottom: unset;
  }
}

a{
  text-decoration: none !important;
  color: var(--color-secondary) !important;
}

//Animation Fade In
.global-fade-in {
  opacity: 0;
  transform: translateY(100px); /* Initial position, move it slightly down */
  transition: opacity 2.5s ease, transform 1s ease; /* Add smooth transition */
}

.global-fade-in.visible {
  opacity: 1;
  transform: translateY(0); /* When in view, move to the original position */
}

//SELECT DROP DOWN
.css-1nmdiq5-menu{ 
  z-index: 99 !important;
}

//Alert
.alert-snackbar{
  margin-top: 80px;
}



// Admin Panel
.container-admin-global{
  display: flex;
  width: 100%;
  background-color: var(--color-secondary-v2);
  height: auto;
}

.content-admin-global{
   width: calc(100% - 250px);
   margin-left: 250px;
   transition: all 0.3s ease-in-out;
   &.active{
      width: 100vw;
      margin-left: 0;
   }
}

.btn-delete-preview {
  position: absolute;
  font-size: x-small;
  color: white;
  top: 10px;
  right: -5px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 3px 6px;
  border-radius: 3px;
  background-color: rgba(240, 0, 1, 0.4);
  cursor: pointer;
  transition: 0.3s;
}

.btn-delete-preview:hover{
  background-color: rgb(240 0 1 / 90%);
}

.btn-outline-theme {
  color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}


//Global Content Admin
.row-global-admin{
  h2{
      border-bottom: 1px solid rgb(225, 223, 223);
      padding-bottom: 10px;
  }
  .desc-header-admin{
      margin-top: 10px;
      margin-bottom: 10px;
  }

  .card{
      border: none;
  }

  .badge{
      font-size: 0.5rem;
  }

  .btn-simpan{
      background-color: var(--color-primary);
      border: none;
      outline: none;
  }

  .btn-import-product{
    background-color: var(--color-primary);
    border: none;
    outline: none;
    float: right;
  }
  .btn-contoh-product{
    background-color: var(--color-secondary) !important;
    border: 1px solid var(--color-primary) !important;
    outline: none !important;
    float: right !important;
    color: var(--color-primary) !important;
    margin-right: 10px !important;

    &.active-download{
      padding: 5px 5px 5px 5px;
      border-radius: 10px;
    }
  }
  .text-saving-admin-global{
    text-align: center;
    font-size: 1.2rem;
    span{
      margin-left: 10px;
      font-weight: 500;
    }
  }
}

//Admin Panel MUI
.MuiTabPanel-root{
  padding: 10px 0 0 0 !important; 
}

.rs-input-group-addon{
  padding: 0 !important;
  border: 1px solid #e5e5e5 !important;
}

.input-group-text{
  border-radius: 0px 0.375rem 0.375rem 0px !important;
  border: none !important;
}

.rs-picker-toggle-value{
  color: var(--color-black) !important;
}






